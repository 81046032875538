<template>
  <v-dialog v-model="dialog" max-width="550px" persistent @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }" ref="addKit">
        <v-card-text class="pt-5">
          <v-form>
            <ERow>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Kit Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.name"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Kit Name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Serial Number"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.kitSerial"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Serial Number"
                    placeholder="110xxxxxx"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Kit Status"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.kitStatus"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Kit Status"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Kit Status"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.type"
                    :items="powerTypes"
                    label="Power Type"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  ></v-select>
                </ValidationProvider>
              </ECol>
              <ECol cols="12" md="12" class="py-0">
                <v-select
                  v-model="editedItem.countryCode"
                  :items="countries"
                  label="Country"
                  dense
                  outlined
                  item-value="value"
                  item-text="name"
                ></v-select>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <v-select
                  v-model="editedItem.locationId"
                  :items="locations"
                  label="Location"
                  dense
                  outlined
                  item-value="value"
                  item-text="name"
                ></v-select>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <Autocomplete
                  v-model="editedItem.cameras"
                  item-value="id"
                  item-text="name"
                  label="Cameras"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  :list-items="cameras"
                  resource="cameras"
                />
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="passes(save)">
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { PowerType, Kit } from "@evercam/shared/types/kit"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { AdminApi } from "@evercam/shared/api/adminApi"
import countries from "@evercam/shared/constants/countries"
import { POWER_TYPES } from "@/components/constants.js"
import Autocomplete from "@evercam/shared/components/Autocomplete"

export default Vue.extend({
  name: "KitDialog",
  components: {
    Autocomplete,
  },
  props: {
    editedKit: {
      type: Object as PropType<Kit>,
      default: () => ({} as Kit),
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      dialog: false,
      editedIndex: -1,
      countries: countries,
      powerTypes: POWER_TYPES,
      editedItem: {
        name: null,
        kitSerial: "",
        kitStatus: "",
        type: "" as PowerType,
        countryId: null,
        locationId: null,
        cameras: [],
      } as Kit,
      defaultItem: {
        name: null,
        kitSerial: "",
        kitStatus: "",
        type: "" as PowerType,
        countryId: null,
        locationId: null,
        cameras: [],
      } as Kit,
      locations: [
        { value: 1, name: "IE Warehouse" },
        { value: 2, name: "Deployed" },
        { value: 3, name: "Customer Storage" },
        { value: 4, name: "US Warehouse" },
        { value: 5, name: "AU Warehouse" },
        { value: 6, name: "UK Warehouse" },
      ],
      cameras: [],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    isNewKit() {
      return !this.editedKit?.id
    },
    formTitle() {
      return this.isNewKit ? "Add Kit" : "Edit Kit"
    },
  },
  watch: {
    editedKit: {
      immediate: true,
      deep: true,
      handler() {
        this.initForm(this.editedKit)
      },
    },
    "$attrs.value": {
      immediate: true,
      handler(value) {
        this.dialog = value
      },
    },
    dialog(val) {
      if (val) {
        this.initForm(this.editedKit)
      }
      this.$emit("input", val)
    },
  },
  methods: {
    async save() {
      if (this.isNewKit) {
        this.doCreateKit()
      } else {
        this.doUpdateKit()
      }
    },
    async doUpdateKit() {
      this.reportStore.loading = true
      await AdminApi.kits
        .updateKit(this.editedItem.id, this.editedItem)
        .then(async (kit) => {
          this.$notifications.success("Kit has been updated.")
          if (!this.inline) {
            this.reportStore.items = this.reportStore.items.map((item, index) =>
              index === this.editedIndex ? { ...this.editedItem, ...kit } : item
            )
          }
          this.$emit("change", this.editedItem)
          this.close()
        })
        .catch((error) => {
          this.$notifications.error({ text: "Failed to update Kit!", error })
        })
        .finally(() => {
          this.reportStore.loading = false
        })
    },
    async doCreateKit() {
      this.reportStore.loading = true
      await AdminApi.kits
        .createKit(this.editedItem)
        .then((res) => {
          this.editedItem.id = res.id
          this.reportStore.items = [...this.reportStore.items, this.editedItem]
          this.$notifications.success("Kit has been added.")
          this.close()
          this.$emit("kit-created", this.editedItem)
        })
        .catch((error) => {
          this.$notifications.error({ text: "Failed to create kit!", error })
        })
        .finally(() => {
          this.reportStore.loading = false
        })
    },
    initForm(kit: Kit) {
      if (kit.cameras) {
        this.cameras = kit.cameras
      } else {
        this.cameras = []
      }

      this.editedIndex = this.reportStore.items.indexOf(kit)
      this.editedItem = Object.assign({}, kit)
    },
    async editItem(kit: Kit) {
      this.initForm(kit)
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.clear()
    },
    clear() {
      this.$refs.addKit.reset()
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
  },
})
</script>
